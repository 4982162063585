<template>
<div>

    <v-container>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <!-- <s-toolbar :color="'#244093'" dark label="Reporte de Usos" class="tran"></s-toolbar> -->
                    <v-card>

                        <v-row justify="center" style="margin: auto">
                            <v-col cols="4" lg="2" md="2" class="mt-0 pl-0">
                                <v-btn width="100%" rounded :color="'info'" small @click="run()">Buscar</v-btn>
                            </v-col>
                            <v-col cols="4" lg="2" md="2" class="mt-0 pl-0">
                                <v-spacer></v-spacer>
                                <v-btn @click="show = !show" rounded :color="'warning'" small>
                                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>Descargar
                                </v-btn>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-card flat>
                <v-expand-transition>
                    <div v-show="show">
                        <v-divider></v-divider>
                        <v-row justify="center" style="">

                            <v-col cols="12" lg="12" md="12" class="mt-3 ml-5">
                                <v-btn rounded :color="'error'" small @click="downloadOne()">Un Solo .xls</v-btn>
                                <v-btn rounded :color="'error'" small @click="downloadAll()">Varios .xls</v-btn>
                            </v-col>

                        </v-row>

                    </div>
                </v-expand-transition>

            </v-card>

            <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
                <v-card color="primary" dark>
                    <v-card-text v-if="messageProcessing.length == 0">
                        Por favor espere
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                    <v-card-text v-else>
                        <div class="text--white pt-4">
                            Error al realizar busqueda <br />
                            {{ messageProcessing }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
        <v-container>
            <v-tabs v-model="currentItem">
                <v-tab href="#GeneralCv"> General CV | RE</v-tab>
                <v-tab href="#BatchCv"> Lote CV</v-tab>
                <v-tab href="#AreaCv"> Area CV</v-tab>
                <v-tab href="#CapitalCv"> Capital CV</v-tab>
                <v-tab href="#BatchRe"> Lote RE</v-tab>
                <v-tab href="#AreaRe"> Area RE</v-tab>
                <v-tab href="#CapitalRe"> Capital RE</v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentItem">
                <v-tab-item :value="'GeneralCv'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="itemsGeneral.length > 0 ? '300px': 'auto'" :headers="headersGeneral" :items="itemsGeneral" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>
                <v-tab-item :value="'BatchCv'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersBatchcv" :items="itemsBatchcv" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'AreaCv'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersAreacv" :items="itemsAreacv" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'CapitalCv'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersCapitalcv" :items="itemsCapitalcv" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'BatchRe'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersBatchre" :items="itemsBatchre" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'AreaRe'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersAreare" :items="itemsAreare" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'CapitalRe'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersCapitalre" :items="itemsCapitalre" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

            </v-tabs-items>
        </v-container>
    </v-container>
</div>
</template>

<script>
import _sClcService from "@/services/ReportClicks/ClcChargeMasive.js";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import * as XLSX from 'xlsx';

export default {
    components: {},

    data() {
        return {
            currentItem: "tab-Funciones",
            processing: false,
            messageProcessing: "",
            txtYearFin: this.$fun.getYear(),

            itemsGeneral: [],

            itemsBatchcv: [],
            itemsAreacv: [],
            itemsCapitalcv: [],

            itemsBatchre: [],
            itemsAreare: [],
            itemsCapitalre: [],

            headersGeneral: [],
            
            headersBatchcv: [],
            headersAreacv: [],
            headersCapitalcv: [],

            headersBatchre: [],
            headersAreare: [],
            headersCapitalre: [],

            itemsAll: [],
            show: false,

        }

    },

    methods: {

        run() {
            this.runViewGeneral()
            this.runView()
            this.runView2()
            this.runView3()
            this.runView4()
            this.runView5()
            this.runView6()
           
        },

        runViewGeneral() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = '2012' + ', ' + this.txtYearFin //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseGeneralCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsGeneral = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsGeneral[0]).length; i++
                            ) {
                                this.headersGeneral.splice(i, i, {
                                    text: Object.keys(this.itemsGeneral[0])[i],
                                    value: Object.keys(this.itemsGeneral[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        runView() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin; //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseBacthDinamicCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsBatchcv = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsBatchcv[0]).length; i++
                            ) {
                                this.headersBatchcv.splice(i, i, {
                                    text: Object.keys(this.itemsBatchcv[0])[i],
                                    value: Object.keys(this.itemsBatchcv[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView2() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin; //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseAreaDinamicCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsAreacv = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsAreacv[0]).length; i++
                            ) {
                                this.headersAreacv.splice(i, i, {
                                    text: Object.keys(this.itemsAreacv[0])[i],
                                    value: Object.keys(this.itemsAreacv[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView3() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin; //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseCapitalDinamicCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsCapitalcv = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsCapitalcv[0]).length; i++
                            ) {
                                this.headersCapitalcv.splice(i, i, {
                                    text: Object.keys(this.itemsCapitalcv[0])[i],
                                    value: Object.keys(this.itemsCapitalcv[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView4() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin; //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseBacthDinamicRe_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsBatchre = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsBatchre[0]).length; i++
                            ) {
                                this.headersBatchre.splice(i, i, {
                                    text: Object.keys(this.itemsBatchre[0])[i],
                                    value: Object.keys(this.itemsBatchre[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView5() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin; //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseAreaDinamicRe_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsAreare = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsAreare[0]).length; i++
                            ) {
                                this.headersAreare.splice(i, i, {
                                    text: Object.keys(this.itemsAreare[0])[i],
                                    value: Object.keys(this.itemsAreare[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView6() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin; //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportUseCapitalDinamicRe_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsCapitalre = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsCapitalre[0]).length; i++
                            ) {
                                this.headersCapitalre.splice(i, i, {
                                    text: Object.keys(this.itemsCapitalre[0])[i],
                                    value: Object.keys(this.itemsCapitalre[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadReportGeneral() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearInitial, Yearfin"
            report.QryParamsArrayValue = this.txtYearInitial + ', ' + this.txtYearFin
            report.QryNameProcedure = "ClcReportUseGeneralCv_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! General CV|RE"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin
            report.QryNameProcedure = "ClcReportUseBacthDinamicCv_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Lotes CV"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport2() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin
            report.QryNameProcedure = "ClcReportUseAreaDinamicCv_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Area CV"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport3() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin
            report.QryNameProcedure = "ClcReportUseCapitalDinamicCv_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Capital CV"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport4() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin
            report.QryNameProcedure = "ClcReportUseBacthDinamicRe_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Lotes RE"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport5() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin
            report.QryNameProcedure = "ClcReportUseAreaDinamicRe_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Area RE"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReport6() {

            var report = {}
            this.messageProcessing = ""

            report.QryNameProcedure = "";
            report.QryParamsArray = "YearFin"
            report.QryParamsArrayValue = this.txtYearFin
            report.QryNameProcedure = "ClcReportUseCapitalDinamicRe_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte - Usos ! Capital RE"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadAll() {
            this.downloadReport()
            this.downloadReport2()
            this.downloadReport3()
            this.downloadReport4()
            this.downloadReport5()
            this.downloadReport6()
            this.downloadReportGeneral()
        },

        downloadOne() {

            const workSheet1 = XLSX.utils.json_to_sheet(this.itemsBatchcv);
            const workSheet2 = XLSX.utils.json_to_sheet(this.itemsAreacv);
            const workSheet3 = XLSX.utils.json_to_sheet(this.itemsCapitalcv);

            const workSheet4 = XLSX.utils.json_to_sheet(this.itemsBatchre);
            const workSheet5 = XLSX.utils.json_to_sheet(this.itemsAreare);
            const workSheet6 = XLSX.utils.json_to_sheet(this.itemsCapitalre);
            const workSheet7 = XLSX.utils.json_to_sheet(this.itemsGeneral);

            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet7, "General CV|RE");
            XLSX.utils.book_append_sheet(workBook, workSheet1, "Lote Cv");
            XLSX.utils.book_append_sheet(workBook, workSheet2, "Area Cv");
            XLSX.utils.book_append_sheet(workBook, workSheet3, "Capital Cv");

            XLSX.utils.book_append_sheet(workBook, workSheet4, "Lote Re");
            XLSX.utils.book_append_sheet(workBook, workSheet5, "Area Re");
            XLSX.utils.book_append_sheet(workBook, workSheet6, "Capital Re");

            //buffer
            let buffer = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer"
            });

            //binary
            XLSX.write(workBook, {
                bookType: "xlsx",
                type: "binary"
            });
            //donwload
            XLSX.writeFile(workBook, "ReporteUsos.xlsx");

        },

    }
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
