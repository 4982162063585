<template>
<div>
    <v-container class="px-0">
        <v-col cols="12">
            <v-row>
                <v-col cols="12" lg="6" md="6">

                    <high-charts-drill-bar 
                        :id="1" 
                        :items="itemsLot" 
                        :visibleSubTitle="true" 
                        :subtitle="'LOTES VENDIDOS POR AÑO'" 
                        :objDrilldown="objDrilldownLot"
                        :name="'Año'"
                        :number="true"
                    ></high-charts-drill-bar>
                    <!-- <high-charts-drill-pie 
                        :id="1" 
                        :items="itemsLot" 
                        :visibleSubTitle="true" 
                        :subtitle="'LOTES VENDIDOS POR AÑO'" 
                        :objDrilldown="objDrilldownLot"
                        :name="'Año'"
                     /> -->
                </v-col>
                <v-col cols="12" lg="6" md="6">
                    <high-charts-drill-bar 
                        :id="2" 
                        :items="itemsArea" 
                        :visibleSubTitle="true" 
                        :subtitle="'M2 VENDIDOS POR AÑO'" 
                        :objDrilldown="objDrilldownArea"
                        :name="'Año'"
                        :valueSuffix="'M2'"
                     />
                </v-col>
                <v-col cols="12" lg="12" md="12" class="mt-3">
                    <high-charts-drill-bar 
                        :id="3" 
                        :items="itemsCapital" 
                        :visibleSubTitle="true" 
                        :subtitle="'TOTAL CAPITAL POR AÑO'" 
                        :objDrilldown="objDrilldownCapital"
                        :name="'Año'"
                        :valueSuffix="'USD'"
                     />
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</div>
</template>

<script>
import HighChartsDrillPie from '../../../components/Utils/HighCharts/HighChartsDrillPie.vue';

import HighChartsDrillBar from '../../../components/Utils/HighCharts/HighChartsDrillBar.vue';
export default {
    props: {
        itemsLot: {
            type: Array,
            default: []
        },
        objDrilldownLot: {
            type: Array,
            default: []
        },

        itemsArea: {
            type: Array,
            default: []
        },

        objDrilldownArea: {
            type: Array,
            default: []
        },

        itemsCapital: {
            type: Array,
            default: []
        },

        objDrilldownCapital: {
            type: Array,
            default: []
        },

    },
    components: {
        HighChartsDrillPie,
        HighChartsDrillBar
    },
    data() {
        return {

        };
    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
